@import "../main/abstracts/index";

.page__header div:not(.off-canvas__search) > .search--preview{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: calc($header__height--mobile + 33px);
    z-index: 1;
    width: 100%;
    justify-content: center;
    pointer-events:  none;
    margin-bottom: 60px;
    padding: 0 24px;
    // height: $header__height--mobile;

    body.has-banner-image &,
    body.has-banner-slider &,
    body.has-banner-video &{
        position: absolute;
        margin-bottom: 0;
    }

    body.has-breaking-ribbon &{
        margin-top: 45px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            margin-top: 50px;
        }
    }

    > *{
        pointer-events: auto;
    }

    @include media-breakpoint-up(lg) {
        top: calc($header__height + 30px);
        margin-bottom: 20px;

        body.has-banner-image &,
        body.has-banner-slider &,
        body.has-banner-video &{
            top: calc($header__height + 50px);
        }

        body[data-template='home'] &{
            top: calc($header__height + 90px);
        }
    }

    // html.headroom--unpinned & {
    //     @include media-breakpoint-up(xxl) {
    //         height: $header__height--unpinned;
    //     }
    // }
}

.off-canvas__search {
    height: auto;
    padding: 10px 75px 10px 24px;
    background-color: $color__gray--light;

    @media (min-width: 670px) {
        padding: 10px 24px;
    }
  }
